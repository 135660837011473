import React from 'react';
import { Button } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { getImageSize } from 'polotno/utils/image';
import { getVideoSize, getVideoPreview } from 'polotno/utils/video';
import { localFileToURL } from 'polotno/utils/file';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { t } from 'polotno/utils/l10n';
import { getCrop } from 'polotno/utils/image';

const uploads = observable([]);

const handleSetUploads = (event) => {
  if (event.data?.type !== 'setUploads') {
    return;
  }
  if (event.data?.uploads) {
    uploads.replace(event.data.uploads);
  }
};
window.addEventListener('message', handleSetUploads, false);

function getType(file) {
  const { type } = file;
  if (type.indexOf('svg') >= 0) {
    return 'svg';
  }
  if (type.indexOf('image') >= 0) {
    return 'image';
  }
  if (type.indexOf('video') >= 0) {
    return 'video';
  }
  return 'image';
}

export const UploadPanel = observer(({ store }) => {
  const [isUploading, setUploading] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleFileInput = async (e) => {
    const { target } = e;
    setUploading(true);
    for (const file of target.files) {
      const url = await localFileToURL(file);
      const id = Math.random().toString(36).substr(2, 9);
      const type = getType(file);
      let preview = url;
      if (type === 'video') {
        preview = await getVideoPreview(url);
      }
      uploads.push({ url, type, preview, id });
    }
    setUploading(false);
    target.value = '';
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ height: '45px', paddingTop: '5px' }}>
        {t('sidePanel.uploadTip')}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="input-file">
          <Button
            icon="upload"
            style={{ width: '100%' }}
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            {t('sidePanel.uploadImage')}
          </Button>
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={handleFileInput}
            multiple
          />
        </label>
      </div>
      <ImagesGrid
        images={uploads}
        isLoading={isUploading}
        getPreview={(file) => file.preview || file.url}
        onSelect={async (item, pos, element) => {
          const image = item.url;
          const type = item.type || 'image';

          const getSizeFunc = type === 'video' ? getVideoSize : getImageSize;

          let { width, height } = await getSizeFunc(image);

          if (
            element &&
            element.type === 'svg' &&
            element.contentEditable &&
            type === 'image'
          ) {
            element.set({ maskSrc: image });
            return;
          }

          if (
            element &&
            element.type === 'image' &&
            element.contentEditable &&
            type == 'image'
          ) {
            const crop = getCrop(element, {
              width,
              height,
            });
            element.set({ src: image, ...crop });
            return;
          }

          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;

          store.activePage?.addElement({
            type,
            src: image,
            x,
            y,
            width,
            height,
          });
        }}
      />
    </div>
  );
});
