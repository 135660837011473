import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { TemplatesPanel as DefaultTemplatesPanel } from 'polotno/side-panel/templates-panel';
import { nanoid } from 'nanoid';

// we have one client who may (or may not) use custom templates
// so we need to support both cases
const templates = observable([]);

const handleSetTemplates = (event) => {
  if (event.data?.type !== 'setTemplates') {
    return;
  }
  if (event.data?.templates) {
    templates.replace(event.data.templates);
  }
};
window.addEventListener('message', handleSetTemplates, false);

const CustomTemplatesPanel = observer(({ store }) => {
  return (
    <ImagesGrid
      images={templates}
      getPreview={(image) => image.preview}
      isLoading={false}
      onSelect={async (item) => {
        const req = await fetch(item.json);
        const json = await req.json();
        // if we don't have many pages, just load json directly
        if (store.pages.length <= 1) {
          store.loadJSON(json, true);
        } else {
          // other wise the logic is a bit more complicated
          // first take previous JSON
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()));
          // not if size of new template is not the same as current store
          // we need to assign sizes of pages manually
          if (oldJSON.width !== json.width || oldJSON.height !== json.height) {
            // manually set width from store
            json.pages.forEach((page) => {
              page.width = page.width || json.width;
              page.height = page.height || json.height;
            });
          }
          // replace ids to make sure there is not duplicates
          json.pages.forEach((page) => {
            page.id = nanoid(10);
            page.children.forEach((child) => {
              child.id = nanoid(10);
            });
          });
          // then find data of active page
          const index = store.pages.indexOf(store.activePage);
          // then replace it in the new JSON
          oldJSON.pages.splice(index, 1, ...json.pages);
          // now we can load the new JSON
          store.loadJSON(oldJSON, true);
        }
      }}
      loadMore={false}
    />
  );
});

export const TemplatesPanel = observer(({ store }) => {
  if (templates.length === 0) {
    return <DefaultTemplatesPanel store={store} />;
  }
  return <CustomTemplatesPanel store={store} />;
});
